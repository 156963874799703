@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Roboto+Flex:opsz,wght@8..144,400;8..144,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Roboto&display=swap");
* {
  box-sizing: border-box; }

.aboutus {
  width: 100vw;
  padding-top: 120px;
  height: fit-content;
  box-sizing: border-box; }
  @media only screen and (max-width: 1000px) {
    .aboutus {
      padding-top: 20px; } }
  .aboutus .headings {
    font-family: "Cinzel";
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #474747; }
  .aboutus .dividers {
    display: block;
    width: 80%;
    padding: 0% 0% 5%; }
  .aboutus .smaller-divider {
    padding: 1% 0% 3%; }
  .aboutus .mobiledividers {
    display: none; }
  .aboutus__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content; }
  .aboutus .laughing-img {
    background-image: url("../images/aboutus-main.PNG");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 214.86px;
    background-position: right; }
  .aboutus .content-box-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../images/allbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    .aboutus .content-box-1 .inner {
      width: 100%;
      padding-bottom: 3%;
      background: rgba(255, 255, 255, 0.75);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .aboutus .content-box-1 .inner__para {
        padding: 3% 20% 1%;
        font-family: "Roboto Flex";
        font-weight: 1000;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.03em;
        color: #474747;
        font-stretch: 132; }
      .aboutus .content-box-1 .inner .flex-container-wrapper .flex-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%; }
        .aboutus .content-box-1 .inner .flex-container-wrapper .flex-container__upper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
        .aboutus .content-box-1 .inner .flex-container-wrapper .flex-container__lower {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
        .aboutus .content-box-1 .inner .flex-container-wrapper .flex-container .boxes {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          justify-content: flex-start;
          width: 440px;
          height: 354px;
          margin: 2%;
          padding: 5% 2% 2%;
          background: #fafafa;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px; }
          .aboutus .content-box-1 .inner .flex-container-wrapper .flex-container .boxes__content {
            font-family: "Roboto Flex";
            font-style: normal;
            text-align: justify;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.03em;
            color: #474747; }
          .aboutus .content-box-1 .inner .flex-container-wrapper .flex-container .boxes__vector-img {
            padding-bottom: 10%; }
      .aboutus .content-box-1 .inner .carousel-container {
        width: fit-content;
        height: fit-content;
        display: none;
        padding: 5% 0% 10%; }
        .aboutus .content-box-1 .inner .carousel-container .container-slider {
          max-width: fit-content;
          height: fit-content;
          position: relative; }
        .aboutus .content-box-1 .inner .carousel-container .slide {
          opacity: 0;
          transition: opacity ease-in-out 0.4s;
          display: none; }
        .aboutus .content-box-1 .inner .carousel-container .boxes__content {
          font-family: "Roboto Flex";
          font-style: normal;
          text-align: justify;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          letter-spacing: 0.03em;
          color: #474747; }
        .aboutus .content-box-1 .inner .carousel-container .boxes__vector-img {
          padding-bottom: 10%; }
        .aboutus .content-box-1 .inner .carousel-container .active-anim {
          opacity: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 330px;
          height: 307px;
          padding: 5% 8% 5%;
          background: #fafafa;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px; }
        .aboutus .content-box-1 .inner .carousel-container .btn-slide {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #f1f1f1;
          border: 1px solid rgba(34, 34, 34, 0.287);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
        .aboutus .content-box-1 .inner .carousel-container .btn-slide img {
          width: 5px;
          height: 5px;
          pointer-events: none; }
        .aboutus .content-box-1 .inner .carousel-container .prev {
          position: absolute;
          top: 20vh;
          left: -10px;
          /* transform: translateY(-60%); */ }
        .aboutus .content-box-1 .inner .carousel-container .next {
          position: absolute;
          top: 20vh;
          right: -10px; }
        .aboutus .content-box-1 .inner .carousel-container .container-dots {
          position: absolute;
          bottom: -10%;
          left: 50%;
          transform: translateX(-50%);
          display: flex; }
        .aboutus .content-box-1 .inner .carousel-container .dot {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          border: 3px solid #f1f1f1;
          margin: 0 5px;
          background: rgba(0, 74, 116, 0.35); }
        .aboutus .content-box-1 .inner .carousel-container .dot.active {
          background: #004a74; }
  .aboutus .black-animated-box {
    width: 100%;
    height: fit-content;
    padding: 50px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .aboutus .black-animated-box .animated-text {
      padding: 0 5px;
      font-family: "Cinzel";
      font-weight: 400;
      font-size: 32px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.1em;
      color: #fafafa;
      word-wrap: break-word;
      overflow: hidden; }
  .aboutus .content-box-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../images/allbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: justify; }
    .aboutus .content-box-2__inner {
      width: 100%;
      padding: 3% 16% 5%;
      background: rgba(255, 255, 255, 0.75);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .aboutus .content-box-2__inner .dividers {
        width: 120%;
        padding-bottom: 30px; }
      .aboutus .content-box-2__inner .mobiledividers {
        width: 100%; }
    .aboutus .content-box-2__para {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      text-align: justify;
      letter-spacing: 0.03em;
      color: #474747; }
  .aboutus .content-box-3 {
    background-image: url("../images/visionbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content; }
    .aboutus .content-box-3__inner {
      padding: 3% 0%;
      width: 100%;
      height: fit-content; }
      .aboutus .content-box-3__inner .overlay {
        height: fit-content;
        padding: 2% 0% 2%;
        width: 100%;
        background: rgba(0, 0, 0, 0.8); }
        .aboutus .content-box-3__inner .overlay__heading {
          color: #fafafa;
          padding-bottom: 2%; }
        .aboutus .content-box-3__inner .overlay__para {
          padding: 0% 15%;
          font-family: "Roboto Flex";
          font-weight: 1000;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.03em;
          color: #fafafa;
          font-stretch: 132;
          font-variation-settings: "GRAD" 0, "slnt" -5, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738; }
  .aboutus .content-box-4 {
    padding: 3% 0%;
    display: flex;
    height: fit-content;
    flex-direction: column; }
    .aboutus .content-box-4__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: fit-content; }
    .aboutus .content-box-4__flex-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2% 5%;
      height: fit-content; }
      .aboutus .content-box-4__flex-container .image-container {
        width: 550px;
        height: 405px;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .aboutus .content-box-4__flex-container .image-container .changeableImg {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          top: 0%;
          left: 0%; }
      .aboutus .content-box-4__flex-container .mission-list {
        max-width: 620px;
        min-height: 405px;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
        .aboutus .content-box-4__flex-container .mission-list .mission-items {
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 10px;
          gap: 10px;
          width: 620px;
          background: #f5f5f5;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          margin-bottom: 1%;
          text-align: left;
          cursor: pointer; }
          .aboutus .content-box-4__flex-container .mission-list .mission-items .visible-heading {
            text-align: left;
            font-family: "Roboto Flex";
            font-style: normal;
            font-weight: 1000;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: 0.1em;
            color: #919191; }
          .aboutus .content-box-4__flex-container .mission-list .mission-items .items-content {
            text-align: left;
            display: none;
            font-family: "Roboto Flex";
            font-weight: 400;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: 0.03em;
            color: #474747; }
  .aboutus .content-box-5 {
    display: flex;
    height: fit-content;
    flex-direction: column;
    padding: 3% 0%;
    background-color: #f5f7f8; }
    .aboutus .content-box-5__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .aboutus .content-box-5__flex-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2% 5%;
      height: fit-content; }
      .aboutus .content-box-5__flex-container .image-container {
        width: 550px;
        height: 405px;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .aboutus .content-box-5__flex-container .image-container .changeableImg {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          top: 0%;
          left: 0%; }
      .aboutus .content-box-5__flex-container .mission-list {
        max-width: 620px;
        min-height: 405px;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
        .aboutus .content-box-5__flex-container .mission-list .mission-items {
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 10px;
          gap: 10px;
          width: 620px;
          background: #f5f5f5;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          margin-bottom: 1%;
          text-align: left;
          cursor: pointer; }
          .aboutus .content-box-5__flex-container .mission-list .mission-items .visible-heading {
            text-align: left;
            font-family: "Roboto Flex";
            font-style: normal;
            font-weight: 1000;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: 0.1em;
            color: #919191; }
          .aboutus .content-box-5__flex-container .mission-list .mission-items .items-content {
            text-align: left;
            display: none;
            font-family: "Roboto Flex";
            font-weight: 400;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: 0.03em;
            color: #474747; }
  .aboutus .content-box-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../images/allbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: fit-content; }
    .aboutus .content-box-6 .outer {
      padding: 3% 0%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.75); }
      .aboutus .content-box-6 .outer__inner {
        width: 70%;
        padding: 2% 7%;
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .aboutus .content-box-6 .outer__inner .person-card {
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .aboutus .content-box-6 .outer__inner .person-card .img-frame {
            padding: 2% 0% 5%; }
          .aboutus .content-box-6 .outer__inner .person-card .quote {
            font-family: "Roboto Flex";
            font-style: italic;
            font-weight: 671;
            font-size: 20px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.03em;
            padding: 1% 2%;
            padding-bottom: 0%;
            color: #474747; }
          .aboutus .content-box-6 .outer__inner .person-card .name {
            padding: 2% 0%;
            font-family: "Roboto Flex";
            font-style: normal;
            font-weight: 1000;
            font-size: 32px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.1em;
            color: #474747; }
        .aboutus .content-box-6 .outer__inner .bio {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.03em;
          color: #474747;
          padding: 3%;
          padding-top: 10%; }
      .aboutus .content-box-6 .outer .founder-divider {
        padding: 3% 0% 0%; }
  .aboutus .content-box-7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    background: #fafafa;
    padding-top: 3%;
    width: 100%; }
    .aboutus .content-box-7__flex-container {
      width: 100%;
      padding: 2% 3%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow-x: auto;
      justify-content: space-between; }
      .aboutus .content-box-7__flex-container .groupCompany__cards {
        margin: 0% 2%; }
        .aboutus .content-box-7__flex-container .groupCompany__cards .cards__text {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 1000;
          font-size: 24px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.1em;
          color: #474747; }
        .aboutus .content-box-7__flex-container .groupCompany__cards .cards__img {
          border-radius: 8px;
          width: 420px;
          object-fit: fill;
          height: 450px; }
  @media only screen and (max-width: 768px) {
    .aboutus .mobiledividers {
      padding: 0% 0% 10%;
      width: 80%;
      display: block; }
    .aboutus .dividers {
      display: none; }
    .aboutus .headings {
      padding: 0% 5%; }
    .aboutus .content-box-1 .inner__para {
      padding: 3% 10% 1%;
      font-size: 14px; }
    .aboutus .content-box-2__inner {
      width: 100%;
      padding: 5% 8%; }
    .aboutus .content-box-2__para {
      font-size: 14px; }
    .aboutus .content-box-3__inner {
      padding: 5% 0%; }
      .aboutus .content-box-3__inner .overlay__heading {
        padding-bottom: 1%; }
      .aboutus .content-box-3__inner .overlay__para {
        padding: 3% 10% 1%;
        font-size: 14px; }
    .aboutus .content-box-6 .outer {
      padding: 10% 0%; }
      .aboutus .content-box-6 .outer__inner {
        width: 90%; }
        .aboutus .content-box-6 .outer__inner .person-card {
          width: 100%; }
          .aboutus .content-box-6 .outer__inner .person-card .img-frame {
            transform: scale(0.75, 0.75); }
          .aboutus .content-box-6 .outer__inner .person-card .name {
            font-size: 24px;
            white-space: nowrap; }
          .aboutus .content-box-6 .outer__inner .person-card .quote {
            font-size: 14px;
            padding: 0%; }
        .aboutus .content-box-6 .outer__inner .bio {
          padding-bottom: 7% 0%; }
      .aboutus .content-box-6 .outer .founder-divider {
        padding: 5% 0% 0%; } }
  @media only screen and (max-width: 1000px) {
    .aboutus .laughing-img {
      background-image: url("../images/aboutus-main.PNG");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 136.78px;
      background-size: 100vw; }
    .aboutus .black-animated-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: fit-content;
      padding: 20px; }
      .aboutus .black-animated-box .animated-text {
        font-size: 20px;
        padding: 5px 0; }
    .aboutus .content-box-1 .inner .flex-container-wrapper {
      display: none; }
    .aboutus .content-box-1 .inner .carousel-container {
      display: block; } }
  @media only screen and (max-width: 1350px) {
    .aboutus .content-box-4__flex-container .image-container {
      width: 500px;
      height: 350px; }
      .aboutus .content-box-4__flex-container .image-container .changeableImg {
        width: 100%;
        height: 100%; }
    .aboutus .content-box-4__flex-container .mission-list {
      max-width: 500px;
      min-height: 300px; }
      .aboutus .content-box-4__flex-container .mission-list .mission-items {
        width: auto; }
        .aboutus .content-box-4__flex-container .mission-list .mission-items .visible-heading {
          font-size: 24px; }
        .aboutus .content-box-4__flex-container .mission-list .mission-items .items-content {
          font-size: 14px; }
    .aboutus .content-box-5__flex-container .image-container {
      width: 500px;
      height: 350px; }
      .aboutus .content-box-5__flex-container .image-container .changeableImg {
        width: 100%;
        height: 100%; }
    .aboutus .content-box-5__flex-container .mission-list {
      max-width: 500px;
      min-height: 300px; }
      .aboutus .content-box-5__flex-container .mission-list .mission-items {
        width: auto; }
        .aboutus .content-box-5__flex-container .mission-list .mission-items .visible-heading {
          font-size: 24px; }
        .aboutus .content-box-5__flex-container .mission-list .mission-items .items-content {
          font-size: 14px; } }
  @media only screen and (max-width: 1130px) {
    .aboutus .content-box-4 {
      height: fit-content; }
      .aboutus .content-box-4__flex-container {
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: space-between; }
        .aboutus .content-box-4__flex-container .image-container {
          width: 330px;
          height: 243px; }
          .aboutus .content-box-4__flex-container .image-container .changeableImg {
            width: 100%;
            height: 100%; }
        .aboutus .content-box-4__flex-container .mission-list {
          padding: 5% 0%;
          width: 330px;
          height: fit-content; }
          .aboutus .content-box-4__flex-container .mission-list .mission-items {
            margin-bottom: 3%;
            width: auto;
            height: fit-content; }
            .aboutus .content-box-4__flex-container .mission-list .mission-items .visible-heading {
              font-size: 24px; }
            .aboutus .content-box-4__flex-container .mission-list .mission-items .items-content {
              font-size: 14px; }
    .aboutus .content-box-5 {
      height: fit-content; }
      .aboutus .content-box-5__flex-container {
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        justify-content: space-between; }
        .aboutus .content-box-5__flex-container .image-container {
          width: 330px;
          height: 243px; }
          .aboutus .content-box-5__flex-container .image-container .changeableImg {
            width: 100%;
            height: 100%; }
        .aboutus .content-box-5__flex-container .mission-list {
          padding: 5% 0%;
          width: 330px;
          height: fit-content; }
          .aboutus .content-box-5__flex-container .mission-list .mission-items {
            margin-bottom: 3%;
            width: auto;
            height: fit-content; }
            .aboutus .content-box-5__flex-container .mission-list .mission-items .visible-heading {
              font-size: 24px; }
            .aboutus .content-box-5__flex-container .mission-list .mission-items .items-content {
              font-size: 14px; } }

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%; }

.homepage {
  width: 100vw;
  padding-top: 120px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*--------------Media Query-----------*/ }
  .homepage .home-mobilelogo-container {
    display: none; }
  @media only screen and (max-width: 1000px) {
    .homepage {
      padding-top: 0px; } }
  .homepage .headings {
    font-family: "Cinzel";
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #474747; }
  .homepage .dividers {
    width: 80%;
    padding: 0% 0% 5%; }
  .homepage .mobiledividers {
    display: none; }
  .homepage__carousel-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .homepage__carousel-container .home-slider {
      width: fit-content;
      min-height: 600px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .homepage__carousel-container .home-slider .btn-slide {
        width: 100vw;
        background: none;
        border: none;
        position: absolute; }
        .homepage__carousel-container .home-slider .btn-slide .arrows {
          font-weight: 900;
          color: #004a74; }
        .homepage__carousel-container .home-slider .btn-slide .rightarrow {
          position: absolute;
          left: 50px;
          bottom: 50%; }
        .homepage__carousel-container .home-slider .btn-slide .leftarrow {
          position: absolute;
          right: 50px;
          bottom: 50%; }
      .homepage__carousel-container .home-slider .changeableimg {
        display: none; }
      .homepage__carousel-container .home-slider .active-img {
        display: block;
        padding-bottom: 2%; }
      .homepage__carousel-container .home-slider .dots-container {
        padding: 0% 0% 5%;
        display: flex; }
        .homepage__carousel-container .home-slider .dots-container .homedot {
          width: 18px;
          height: 18px;
          margin: 0 2.5px;
          border-radius: 50%;
          border: 3px solid #f1f1f1;
          background: rgba(0, 74, 116, 0.35); }
        .homepage__carousel-container .home-slider .dots-container .homedot.activedot {
          background: #004a74; }
  .homepage__mobile-carousel {
    width: 100%;
    min-height: 95vh;
    padding: 10% 0% 10%;
    height: fit-content;
    display: none; }
    .homepage__mobile-carousel .home-slider {
      width: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative; }
      .homepage__mobile-carousel .home-slider .images-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap; }
        .homepage__mobile-carousel .home-slider .images-container .inner-img {
          padding: 1% 1%; }
      .homepage__mobile-carousel .home-slider .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.8); }
        .homepage__mobile-carousel .home-slider .content-container__para {
          font-family: "Roboto Flex";
          font-style: italic;
          font-weight: 1000;
          font-size: 24px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.03em;
          color: #474747;
          font-stretch: 132;
          font-variation-settings: "GRAD" 0, "slnt" -5, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738; }
      .homepage__mobile-carousel .home-slider .changeableimg {
        display: none; }
      .homepage__mobile-carousel .home-slider .active-img {
        display: block; }
      .homepage__mobile-carousel .home-slider .dots-container {
        position: absolute;
        left: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .homepage__mobile-carousel .home-slider .dots-container .homedot {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin: 5px;
          border: 3px solid #f1f1f1;
          background: rgba(0, 74, 116, 0.35); }
        .homepage__mobile-carousel .home-slider .dots-container .homedot.activedot {
          background: #004a74; }
  .homepage .content-box-1 {
    background-image: url("../images/allbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .homepage .content-box-1 .inner {
      width: 100%;
      height: fit-content;
      padding: 5% 0%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.75); }
      .homepage .content-box-1 .inner .animated-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
        position: relative;
        overflow-y: hidden;
        width: fit-content; }
        .homepage .content-box-1 .inner .animated-container .animated-img-container {
          margin-left: 10px; }
        .homepage .content-box-1 .inner .animated-container .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          padding-bottom: 25px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          justify-content: flex-end; }
          .homepage .content-box-1 .inner .animated-container .overlay__inner {
            width: fit-content;
            height: fit-content;
            padding: 0% 2%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            background: rgba(255, 255, 255, 0.8); }
            .homepage .content-box-1 .inner .animated-container .overlay__inner .para {
              font-family: "Roboto Flex";
              font-weight: 1000;
              font-size: 36px;
              line-height: 100%;
              text-align: center;
              letter-spacing: 0.03em;
              color: #474747;
              font-stretch: 132;
              font-variation-settings: "GRAD" 0, "slnt" -5, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738; }
        .homepage .content-box-1 .inner .animated-container .animated-overlay {
          background-image: url("../images/overlayimg.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          background-position: left;
          position: absolute;
          width: 100%;
          height: 100%;
          overflow-x: hidden;
          animation: width 30s linear infinite; }

@keyframes width {
  0% {
    width: 0%; }
  15% {
    width: 0%; }
  20% {
    width: 100%; }
  60% {
    width: 100%; }
  65% {
    width: 0%; }
  100% {
    width: 0%; } }
          .homepage .content-box-1 .inner .animated-container .animated-overlay .container {
            padding-bottom: 25px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            justify-content: flex-end; }
            .homepage .content-box-1 .inner .animated-container .animated-overlay .container__inner {
              width: fit-content;
              height: fit-content;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              background: rgba(255, 255, 255, 0.8); }
              .homepage .content-box-1 .inner .animated-container .animated-overlay .container__inner .text {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-right: 2%; }
                .homepage .content-box-1 .inner .animated-container .animated-overlay .container__inner .text .para {
                  font-family: "Roboto Flex";
                  font-weight: 1000;
                  font-size: 36px;
                  line-height: 100%;
                  text-align: center;
                  letter-spacing: 0.03em;
                  white-space: nowrap;
                  color: #474747;
                  font-stretch: 132;
                  font-variation-settings: "GRAD" 0, "slnt" -5, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738; }
  .homepage .content-box-2 {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 2%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .homepage .content-box-2 .btn-div .homepage-btn {
      width: 237px;
      border: 0;
      background: #2596c7;
      padding: 10px 22px 8px;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #ffffff;
      box-shadow: 0px 4px 2px rgba(3, 0, 0, 0.1);
      cursor: pointer; }
    .homepage .content-box-2 .homepage-btn:hover {
      background: #004a74; }
    .homepage .content-box-2 .project-carousel {
      display: none;
      width: 100%;
      height: fit-content;
      padding-top: 3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .homepage .content-box-2 .project-carousel .container-slider {
        max-width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 418px; }
      .homepage .content-box-2 .project-carousel .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; }
      .homepage .content-box-2 .project-carousel .individual-map {
        width: 100vw;
        height: 218px; }
        .homepage .content-box-2 .project-carousel .individual-map .map-container {
          width: 100vw;
          height: 218px; }
      .homepage .content-box-2 .project-carousel .individual-inactive {
        transform: scale(0.75, 0.75); }
      .homepage .content-box-2 .project-carousel .individual {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0% 2% 5%;
        justify-content: space-between;
        width: 336px;
        height: 418px;
        height: fit-content;
        background: #ffffff;
        box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714);
        border-radius: 25px; }
        .homepage .content-box-2 .project-carousel .individual .cardimg {
          width: 336px;
          height: 300px;
          border-radius: 25px 25px 0px 0px; }
        .homepage .content-box-2 .project-carousel .individual .project {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          justify-items: left;
          width: 100%; }
          .homepage .content-box-2 .project-carousel .individual .project__name {
            font-family: "Roboto Flex";
            font-style: normal;
            font-weight: 1000;
            font-size: 24px;
            line-height: 150%;
            width: 90%;
            padding-top: 8px;
            letter-spacing: 0.1em;
            color: #474747;
            text-align: left; }
          .homepage .content-box-2 .project-carousel .individual .project__location {
            font-family: "Roboto Flex";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            width: 90%;
            letter-spacing: 0.03em;
            color: #474747;
            text-align: left; }
        .homepage .content-box-2 .project-carousel .individual .options {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: fit-content;
          justify-content: space-between;
          border-top: 1px solid #e4e4e4;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px; }
          .homepage .content-box-2 .project-carousel .individual .options__types {
            display: flex;
            flex-direction: row;
            width: 33.3%;
            height: fit-content;
            padding: 3% 5% 0%;
            color: #474747;
            text-decoration: none; }
            .homepage .content-box-2 .project-carousel .individual .options__types .type-size {
              height: 100%;
              padding: 1.5px 0 0 8px;
              white-space: nowrap; }
          .homepage .content-box-2 .project-carousel .individual .options .withborder {
            border-left: 1px solid #e4e4e4;
            border-right: 1px solid #e4e4e4; }
      .homepage .content-box-2 .project-carousel .btn-slide {
        width: 100vw;
        background: none;
        border: none;
        position: absolute;
        bottom: 60%; }
        .homepage .content-box-2 .project-carousel .btn-slide .arrows {
          font-weight: 900;
          color: #004a74;
          z-index: 5; }
        .homepage .content-box-2 .project-carousel .btn-slide .rightarrow {
          position: absolute;
          left: 50px;
          bottom: 60%; }
        .homepage .content-box-2 .project-carousel .btn-slide .leftarrow {
          position: absolute;
          right: 50px;
          bottom: 60%; }
      .homepage .content-box-2 .project-carousel .dot {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 3px solid #f1f1f1;
        margin: 0 5px;
        background: rgba(0, 74, 116, 0.35); }
      .homepage .content-box-2 .project-carousel .dot.active {
        background: #004a74; }
    .homepage .content-box-2 .mobile-cards-container {
      display: none;
      width: 100%;
      height: fit-content;
      overflow-x: scroll; }
      .homepage .content-box-2 .mobile-cards-container .container-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0% 5%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start; }
        .homepage .content-box-2 .mobile-cards-container .container-inner .upper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          justify-content: space-between;
          width: fit-content; }
          .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project {
            width: 200px;
            height: 274px;
            border-radius: 25px;
            position: relative;
            box-shadow: 0px, 32px rgba(0, 0, 0, 0.1337);
            margin: 2% 0.75% 2%; }
            .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project .project-img {
              width: 100%;
              height: 100%;
              border-radius: 25px;
              border-radius: 25px;
              box-shadow: 0px, 32px rgba(0, 0, 0, 0.1337); }
            .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project .tohover {
              position: absolute;
              bottom: 0px;
              width: 100%;
              height: 35px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: #2596c7;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;
              padding: 5px 5px;
              font-family: "Roboto Flex";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              text-align: center;
              letter-spacing: 0.03em;
              color: #ffffff; }
            .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project .overlay-details {
              display: none; }
            .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .tohover {
              display: none; }
            .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0%;
              background-color: rgba(255, 255, 255, 0.7);
              border-radius: 25px;
              text-align: left; }
              .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section {
                width: 100%;
                padding: 7% 5% 2%; }
                .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .name {
                  font-family: "Roboto Flex";
                  font-size: 16px;
                  color: #474747;
                  font-weight: 600; }
                .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .location {
                  font-family: "Roboto Flex";
                  font-size: 12px;
                  color: #474747;
                  font-weight: lighter; }
                .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .options {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  justify-content: space-between; }
                  .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .options .type-size {
                    font-family: "Roboto Flex";
                    font-size: 14px;
                    font-weight: bold;
                    color: #000000; }
                .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .link-btn {
                  text-decoration: none; }
                .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .know-btn {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  background: #2596c7;
                  padding: 5px 5px;
                  font-family: "Roboto Flex";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 150%;
                  text-align: center;
                  letter-spacing: 0.03em;
                  color: #ffffff; }
                  .homepage .content-box-2 .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .know-btn:hover {
                    background: #0f497e; }
      .homepage .content-box-2 .mobile-cards-container .lower {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-bottom: 5%; }
        .homepage .content-box-2 .mobile-cards-container .lower .individual-map {
          width: 340px;
          height: 268px; }
          .homepage .content-box-2 .mobile-cards-container .lower .individual-map .map-container {
            width: 100%;
            height: 268px;
            background: #ffffff;
            box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714); }
  @media only screen and (max-width: 1000px) {
    .homepage .content-box-2 .project-carousel {
      display: none; }
    .homepage .content-box-2 .mobile-cards-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  @media only screen and (max-width: 900px) {
    .homepage .home-mobilelogo-container {
      display: block;
      position: absolute;
      top: 5px;
      width: fit-content; }
      .homepage .home-mobilelogo-container .home-mobilelogo-img {
        width: 120px; }
    .homepage__carousel-container {
      display: none; }
    .homepage .content-box-1 {
      display: none; }
    .homepage .content-box-2 .card-container {
      padding: 0% 2%; }
    .homepage .content-box-2 .btn-div {
      padding-bottom: 5%; }
    .homepage__mobile-carousel {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .homepage .headings {
      padding: 0% 5%; }
    .homepage .dividers {
      display: none; }
    .homepage .mobiledividers {
      width: 100%;
      padding: 0% 0% 10%;
      display: block; } }

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%; }

.diversification {
  width: 100vw;
  padding-top: 120px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*--------------Media Query-----------*/ }
  @media only screen and (max-width: 1000px) {
    .diversification {
      padding-top: 20px; } }
  .diversification .headings {
    font-family: "Cinzel";
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #474747; }
  .diversification .dividers {
    width: 80%;
    padding: 0% 0% 5%; }
  .diversification .mobiledividers {
    display: none; }
  .diversification .content-box-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .diversification .content-box-1 .para {
      padding: 0% 15%;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: justify;
      letter-spacing: 0.03em;
      color: #474747; }
    .diversification .content-box-1 .flex-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 200vw;
      padding: 2% 10%; }
      .diversification .content-box-1 .flex-container .boxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0% 2%; }
        .diversification .content-box-1 .flex-container .boxes .box-images {
          width: 330.59px;
          height: 406.11px;
          border-radius: 10px;
          object-fit: fill; }
        .diversification .content-box-1 .flex-container .boxes__text {
          padding: 2% 0%;
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 1000;
          font-size: 24px;
          line-height: 150%;
          text-align: justify;
          letter-spacing: 0.1em;
          color: #474747; }
        .diversification .content-box-1 .flex-container .boxes__content {
          padding: 20px;
          gap: 10px;
          width: 300px;
          height: 425px;
          background: #f5f5f5;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: justify;
          letter-spacing: 0.03em;
          color: #474747; }
  .diversification .diversification-carousel {
    display: none;
    width: 100%; }
    .diversification .diversification-carousel .container-slider {
      max-width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse; }
    .diversification .diversification-carousel .slide {
      opacity: 0;
      transition: opacity ease-in-out 0.4s;
      display: none; }
    .diversification .diversification-carousel .boxes__heading {
      padding: 2% 0%;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 1000;
      font-size: 24px;
      line-height: 150%;
      text-align: justify;
      letter-spacing: 0.1em;
      color: #474747; }
    .diversification .diversification-carousel .boxes__content {
      padding: 20px;
      gap: 10px;
      width: 300px;
      height: fit-content;
      background: #f5f5f5;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: justify;
      letter-spacing: 0.03em;
      color: #474747; }
    .diversification .diversification-carousel .boxes__vector-img {
      width: 304.59px;
      height: 406.11px;
      border-radius: 10px; }
    .diversification .diversification-carousel .active-anim {
      opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5% 8% 5%;
      border-radius: 10px; }
    .diversification .diversification-carousel .container-dots {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
    .diversification .diversification-carousel .btn-slide {
      background: none;
      border: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #004a74; }
    .diversification .diversification-carousel .dot {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 3px solid #f1f1f1;
      margin: 0 5px;
      background: rgba(0, 74, 116, 0.35); }
    .diversification .diversification-carousel .dot.active {
      background: #004a74; }
  .diversification .content-box-2 {
    width: 100%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .diversification .content-box-2 .diverse-btn {
      width: 237px;
      border: 0;
      background: #2596c7;
      padding: 10px 22px 8px;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #ffffff;
      box-shadow: 0px 4px 2px rgba(3, 0, 0, 0.1); }
    .diversification .content-box-2 .diverse-btn:hover {
      background: #004a74; }
    .diversification .content-box-2 .flex-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      flex-wrap: wrap; }
      .diversification .content-box-2 .flex-container .options {
        width: 250.58px;
        height: 117.31px;
        display: flex;
        justify-content: flex-start; }
        .diversification .content-box-2 .flex-container .options .map-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 178.48px;
          height: 73.31px; }
        .diversification .content-box-2 .flex-container .options__text {
          padding: 0px 10px;
          font-family: "Roboto Flex";
          font-style: normal;
          text-align: justify;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.03em;
          color: #474747; }
  @media only screen and (max-width: 1000px) {
    .diversification .content-box-1 {
      display: none; }
    .diversification .dividers {
      display: none; }
    .diversification .mobiledividers {
      width: 80%;
      padding: 0% 0% 10%;
      display: block; }
    .diversification .headings {
      padding: 0% 5%; }
    .diversification .diversification-carousel {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .diversification .content-box-2 {
      padding-top: 10%; }
      .diversification .content-box-2 .flex-container {
        padding-top: 5%; }
        .diversification .content-box-2 .flex-container .options {
          width: 250.58px;
          height: fit-content;
          padding: 5% 0%; } }

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%; }

.projects {
  width: 100vw;
  padding-top: 60px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*--------------Media Query-----------*/ }
  @media only screen and (max-width: 1000px) {
    .projects {
      padding-top: 0px; } }
  .projects .headings {
    font-family: "Cinzel";
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #474747; }
  .projects .not-present {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 1000;
    font-size: 24px;
    padding: 0 5%;
    letter-spacing: 0.1em;
    color: #474747;
    text-align: center; }
  .projects .dividers {
    width: 80%;
    padding: 0% 0% 5%; }
  .projects .mobiledividers {
    display: none; }
  .projects .loader {
    width: 336px;
    height: 418px;
    border-radius: 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .projects .project-carousel {
    display: none;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .projects .project-carousel .container-slider {
      max-width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      min-height: 418px; }
    .projects .project-carousel .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
    .projects .project-carousel .individual-map {
      width: 100vw;
      height: 218px; }
      .projects .project-carousel .individual-map .map-container {
        width: 100vw;
        height: 218px; }
    .projects .project-carousel .individual-inactive {
      transform: scale(0.75, 0.75); }
    .projects .project-carousel .individual {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0% 2% 5%;
      justify-content: space-between;
      width: 336px;
      height: 418px;
      height: fit-content;
      background: #ffffff;
      box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714);
      border-radius: 25px; }
      .projects .project-carousel .individual .cardimg {
        width: 336px;
        height: 300px;
        border-radius: 25px 25px 0px 0px; }
      .projects .project-carousel .individual .project {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-items: left;
        width: 100%; }
        .projects .project-carousel .individual .project__name {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 1000;
          font-size: 24px;
          line-height: 150%;
          width: 90%;
          padding-top: 8px;
          letter-spacing: 0.1em;
          color: #474747;
          text-align: left; }
        .projects .project-carousel .individual .project__location {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          width: 90%;
          letter-spacing: 0.03em;
          color: #474747;
          text-align: left; }
      .projects .project-carousel .individual .options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        justify-content: space-between;
        border-top: 1px solid #e4e4e4;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px; }
        .projects .project-carousel .individual .options__types {
          display: flex;
          flex-direction: row;
          width: 33.3%;
          height: fit-content;
          padding: 3% 5% 0%;
          color: #474747;
          text-decoration: none; }
          .projects .project-carousel .individual .options__types .type-size {
            height: 100%;
            padding: 1.5px 0 0 8px;
            white-space: nowrap; }
        .projects .project-carousel .individual .options .withborder {
          border-left: 1px solid #e4e4e4;
          border-right: 1px solid #e4e4e4; }
    .projects .project-carousel .btn-slide {
      width: 100vw;
      background: none;
      border: none;
      position: absolute;
      bottom: 60%; }
      .projects .project-carousel .btn-slide .arrows {
        font-weight: 900;
        color: #004a74;
        z-index: 5; }
      .projects .project-carousel .btn-slide .rightarrow {
        position: absolute;
        left: 50px;
        bottom: 60%; }
      .projects .project-carousel .btn-slide .leftarrow {
        position: absolute;
        right: 50px;
        bottom: 60%; }
    .projects .project-carousel .dot {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 3px solid #f1f1f1;
      margin: 0 5px;
      background: rgba(0, 74, 116, 0.35); }
    .projects .project-carousel .dot.active {
      background: #004a74; }
  .projects .card-container {
    height: fit-content;
    padding: 0% 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: space-between; }
    .projects .card-container .individual {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0% 2% 5%;
      justify-content: space-between;
      width: 336px;
      height: 418px;
      height: fit-content;
      background: #ffffff;
      box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714);
      border-radius: 25px; }
      .projects .card-container .individual .map-container {
        width: 336px;
        height: 418px;
        border-radius: 25px 25px; }
      .projects .card-container .individual .cardimg {
        width: 336px;
        height: 300px;
        border-radius: 25px 25px 0px 0px; }
      .projects .card-container .individual .project {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-items: left;
        width: 100%; }
        .projects .card-container .individual .project__name {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 1000;
          font-size: 24px;
          line-height: 150%;
          width: 90%;
          padding-top: 8px;
          letter-spacing: 0.1em;
          color: #474747;
          text-align: left; }
        .projects .card-container .individual .project__location {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          width: 90%;
          letter-spacing: 0.03em;
          color: #474747;
          text-align: left; }
      .projects .card-container .individual .options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: fit-content;
        justify-content: space-between;
        border-top: 1px solid #e4e4e4;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px; }
        .projects .card-container .individual .options__types {
          display: flex;
          flex-direction: row;
          width: 33.3%;
          height: fit-content;
          padding: 3% 5% 0%;
          color: #474747;
          text-decoration: none; }
          .projects .card-container .individual .options__types .type-size {
            height: 100%;
            padding: 1.5px 0 0 8px;
            white-space: nowrap; }
        .projects .card-container .individual .options .withborder {
          border-left: 1px solid #e4e4e4;
          border-right: 1px solid #e4e4e4; }
  .projects .mobile-cards-container {
    display: none;
    width: 100%;
    height: fit-content;
    overflow-x: scroll; }
    .projects .mobile-cards-container .container-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0% 5%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: flex-start; }
      .projects .mobile-cards-container .container-inner .upper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
        width: fit-content; }
        .projects .mobile-cards-container .container-inner .upper .project {
          width: 200px;
          height: 274px;
          border-radius: 25px;
          position: relative;
          box-shadow: 0px, 32px rgba(0, 0, 0, 0.1337);
          margin: 2% 0.75% 2%; }
          .projects .mobile-cards-container .container-inner .upper .project .project-img {
            width: 200px;
            height: 274px;
            border-radius: 25px;
            border-radius: 25px;
            box-shadow: 0px, 32px rgba(0, 0, 0, 0.1337); }
          .projects .mobile-cards-container .container-inner .upper .project .tohover {
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #2596c7;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            padding: 5px 5px;
            font-family: "Roboto Flex";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.03em;
            color: #ffffff; }
          .projects .mobile-cards-container .container-inner .upper .project .overlay-details {
            display: none; }
          .projects .mobile-cards-container .container-inner .upper .project:hover .tohover {
            display: none; }
          .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0%;
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 25px;
            text-align: left; }
            .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section {
              width: 100%;
              padding: 7% 5% 2%; }
              .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .name {
                font-family: "Roboto Flex";
                font-size: 16px;
                color: #474747;
                font-weight: 600; }
              .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .location {
                font-family: "Roboto Flex";
                font-size: 12px;
                color: #474747;
                font-weight: lighter; }
              .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .options {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                justify-content: space-between; }
                .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .options .type-size {
                  font-family: "Roboto Flex";
                  font-size: 14px;
                  font-weight: bold;
                  color: #000000; }
              .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .link-btn {
                text-decoration: none; }
              .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .know-btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #2596c7;
                padding: 5px 5px;
                font-family: "Roboto Flex";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                text-align: center;
                letter-spacing: 0.03em;
                color: #ffffff; }
                .projects .mobile-cards-container .container-inner .upper .project:hover .overlay-details .inner-section .know-btn:hover {
                  background: #0f497e; }
    .projects .mobile-cards-container .lower {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .projects .mobile-cards-container .lower .individual-map {
        width: 340px;
        height: 268px; }
        .projects .mobile-cards-container .lower .individual-map .map-container {
          width: 100%;
          height: 268px;
          background: #ffffff;
          box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714); }
  .projects .content-box-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0%; }
  .projects .content-box-2 {
    width: 100%;
    height: fit-content;
    background-image: url("../images/allbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .projects .content-box-2 .inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5% 0%;
      background: rgba(255, 255, 255, 0.75); }
  .projects .content-box-3 {
    width: 100%;
    padding: 5% 0%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .projects .content-box-4 {
    width: 100%;
    height: fit-content;
    background-image: url("../images/allbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .projects .content-box-4 .inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5% 0% 3%;
      background: rgba(255, 255, 255, 0.75); }
      .projects .content-box-4 .inner .mobile-cards-container .container-inner .upper .project {
        margin: 0.5% 0.25% 0.5%; }
  @media only screen and (max-width: 1000px) {
    .projects .card-container {
      display: none; }
    .projects .project-carousel {
      display: none; }
    .projects .destkop-not-present {
      display: none; }
    .projects .mobile-cards-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  @media only screen and (max-width: 900px) {
    .projects .dividers {
      display: none; }
    .projects .mobiledividers {
      width: 80%;
      padding: 0% 0% 5%;
      display: block; }
    .projects .headings {
      padding: 0% 5%; }
    .projects .not-present {
      font-size: 16px; } }

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%; }

.individual-project {
  width: 100vw;
  padding-top: 120px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media only screen and (max-width: 1000px) {
    .individual-project {
      padding-top: 20px; } }
  .individual-project .headings {
    font-family: "Cinzel";
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #474747; }
  .individual-project .dividers {
    width: 80%;
    padding: 0% 0% 5%; }
  .individual-project .mobiledividers {
    display: none; }
  .individual-project .content-box-1 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .individual-project .content-box-1 .inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      .individual-project .content-box-1 .inner .img-container {
        width: 70vw;
        height: 80vh; }
        .individual-project .content-box-1 .inner .img-container .project-img {
          width: 100%;
          height: 100%; }
      .individual-project .content-box-1 .inner .elements-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .individual-project .content-box-1 .inner .elements-container .boxes {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 0.25px solid #474747;
          background-color: #fafafa;
          width: 193px;
          height: 20vh; }
  .individual-project .content-box-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5%; }
    .individual-project .content-box-2 .btn-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 3%; }
      .individual-project .content-box-2 .btn-container .btn-div {
        padding: 0% 2%; }
        .individual-project .content-box-2 .btn-container .btn-div .indproject-btn {
          width: 237px;
          border: 0;
          background: #2596c7;
          padding: 10px 11px;
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.03em;
          color: #ffffff;
          box-shadow: 0px 4px 2px rgba(3, 0, 0, 0.1);
          cursor: pointer; }
          .individual-project .content-box-2 .btn-container .btn-div .indproject-btn:hover {
            background: #004a74; }
    .individual-project .content-box-2 .title {
      font-family: "Roboto Flex";
      font-style: italic;
      font-weight: 671;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #474747;
      font-stretch: 132;
      font-variation-settings: "GRAD" 0, "slnt" -5, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738;
      padding-bottom: 2%; }
    .individual-project .content-box-2 .description {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: justify;
      letter-spacing: 0.03em;
      color: #474747;
      padding: 0% 10% 2%; }
  .individual-project .content-box-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5%; }
    .individual-project .content-box-3 .flex-container {
      height: fit-content;
      padding: 0% 5%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap; }
      .individual-project .content-box-3 .flex-container .individual {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: flex-start;
        margin: 0% 2% 5%;
        width: 336px;
        height: 400px;
        background: #ffffff;
        box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714);
        border-radius: 25px; }
        .individual-project .content-box-3 .flex-container .individual .map-container {
          width: 336px;
          height: 418px;
          border-radius: 25px 25px; }
        .individual-project .content-box-3 .flex-container .individual .list-heading {
          width: 336px;
          padding: 4% 2% 0;
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 553;
          font-size: 24px;
          line-height: 150%;
          letter-spacing: 0.05em;
          color: #474747;
          font-stretch: 100;
          font-variation-settings: "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738; }
        .individual-project .content-box-3 .flex-container .individual .list {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          justify-content: space-between;
          height: 300px;
          width: 336px; }
          .individual-project .content-box-3 .flex-container .individual .list .outer {
            display: flex; }
            .individual-project .content-box-3 .flex-container .individual .list .outer .bullet {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding-left: 30px; }
            .individual-project .content-box-3 .flex-container .individual .list .outer .elements {
              display: flex;
              align-items: center;
              width: 300px;
              height: 40px;
              font-family: "Roboto Flex";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              text-align: left;
              letter-spacing: 0.03em;
              color: #474747; }
              .individual-project .content-box-3 .flex-container .individual .list .outer .elements .text {
                padding: 0 30px 0 5%;
                margin: 0%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                justify-content: flex-end; }
  .individual-project .amenitites {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0% 0% 5%; }
    .individual-project .amenitites .individual {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      justify-content: flex-start;
      margin: 0% 2% 5%;
      width: 336px;
      height: 400px;
      background: #ffffff;
      box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714);
      border-radius: 25px; }
      .individual-project .amenitites .individual .map-container {
        width: 336px;
        height: 418px;
        border-radius: 25px 25px; }
      .individual-project .amenitites .individual .list-heading {
        width: 336px;
        padding: 4% 2% 0;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 553;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.05em;
        color: #474747;
        font-stretch: 100;
        font-variation-settings: "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738; }
      .individual-project .amenitites .individual .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
        height: 300px;
        width: 336px; }
        .individual-project .amenitites .individual .list .outer {
          display: flex; }
          .individual-project .amenitites .individual .list .outer .bullet {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-left: 30px; }
          .individual-project .amenitites .individual .list .outer .elements {
            display: flex;
            align-items: center;
            width: 300px;
            height: 40px;
            font-family: "Roboto Flex";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            text-align: left;
            letter-spacing: 0.03em;
            color: #474747; }
            .individual-project .amenitites .individual .list .outer .elements .text {
              padding: 0 30px 0 5%;
              margin: 0%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              justify-content: flex-end; }
  .individual-project .content-box-4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5%; }
    .individual-project .content-box-4 .flex-container {
      height: fit-content;
      padding: 0% 5% 0%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow-x: auto;
      overflow-y: hidden; }
      .individual-project .content-box-4 .flex-container .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: flex-start;
        margin: 0% 2% 5%;
        padding: 3%;
        width: 336px;
        height: fit-content;
        background: #ffffff;
        box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714);
        border-radius: 25px; }
        .individual-project .content-box-4 .flex-container .card .card-divider-medium {
          width: 70%;
          padding: 7px 0 20px; }
        .individual-project .content-box-4 .flex-container .card .customer {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 1000;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.1em;
          color: #474747; }
        .individual-project .content-box-4 .flex-container .card .occupation {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.03em;
          color: #474747;
          padding-top: 5px; }
        .individual-project .content-box-4 .flex-container .card .paragraph {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: justify;
          letter-spacing: 0.03em;
          color: #474747;
          position: relative; }
          .individual-project .content-box-4 .flex-container .card .paragraph .quote-icon {
            position: absolute;
            left: -20px;
            top: -22px;
            padding-top: 15px; }
        .individual-project .content-box-4 .flex-container .card .caption {
          font-family: "Roboto Flex";
          font-style: medium;
          font-weight: 500;
          font-size: 24px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.1em;
          color: #474747;
          padding-top: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .individual-project .content-box-4 .flex-container .card .caption .building-icon {
            font-size: 32px;
            padding-right: 10px;
            color: #004a74; }
    .individual-project .content-box-4 .mobile-testimonail {
      display: none; }
      .individual-project .content-box-4 .mobile-testimonail .container-slider {
        max-width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse; }
      .individual-project .content-box-4 .mobile-testimonail .slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: flex-start;
        margin: 0% 2% 5%;
        padding: 3%;
        width: 336px;
        height: fit-content;
        background: #ffffff;
        box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714);
        border-radius: 25px; }
        .individual-project .content-box-4 .mobile-testimonail .slide .card-divider-medium {
          width: 70%;
          padding: 7px 0 20px; }
        .individual-project .content-box-4 .mobile-testimonail .slide .customer {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 1000;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.1em;
          color: #474747; }
        .individual-project .content-box-4 .mobile-testimonail .slide .occupation {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.03em;
          color: #474747;
          padding-top: 5px; }
        .individual-project .content-box-4 .mobile-testimonail .slide .paragraph {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: justify;
          letter-spacing: 0.03em;
          color: #474747;
          position: relative; }
          .individual-project .content-box-4 .mobile-testimonail .slide .paragraph .quote-icon {
            position: absolute;
            left: -20px;
            top: -22px;
            padding-top: 15px; }
        .individual-project .content-box-4 .mobile-testimonail .slide .caption {
          font-family: "Roboto Flex";
          font-style: medium;
          font-weight: 500;
          font-size: 24px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.1em;
          color: #474747;
          padding-top: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .individual-project .content-box-4 .mobile-testimonail .slide .caption .building-icon {
            font-size: 32px;
            padding-right: 10px;
            color: #004a74; }
      .individual-project .content-box-4 .mobile-testimonail .slide {
        opacity: 0;
        transition: opacity ease-in-out 0.4s;
        display: none; }
      .individual-project .content-box-4 .mobile-testimonail .active-anim {
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: flex-start;
        margin: 0% 2% 5%;
        padding: 3% 10%;
        width: 336px;
        height: fit-content;
        background: #ffffff;
        box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.133714);
        border-radius: 25px; }
      .individual-project .content-box-4 .mobile-testimonail .container-dots {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 2%; }
      .individual-project .content-box-4 .mobile-testimonail .btn-slide {
        background: none;
        border: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #004a74; }
      .individual-project .content-box-4 .mobile-testimonail .dot {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 3px solid #f1f1f1;
        margin: 0 5px;
        background: rgba(0, 74, 116, 0.35); }
      .individual-project .content-box-4 .mobile-testimonail .dot.active {
        background: #004a74; }
  .individual-project .content-box-floor-plan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 15px;
    padding-top: 5%;
    width: 100%;
    height: fit-content;
    background: #F5F7F8; }
    .individual-project .content-box-floor-plan .flex-container-floor-plan {
      display: flex;
      flex-direction: column;
      height: fit-content;
      padding: 0% 5% 0%;
      width: 120%;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: space-between; }
      .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-flats-ttl {
        display: flex;
        width: 50%;
        justify-content: space-around;
        align-items: center;
        margin: auto; }
        .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-flats-ttl .flat-ttl {
          border: 1px solid #004A74;
          border-radius: 50px;
          padding: 10px 22px 8px;
          gap: 2px; }
          .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-flats-ttl .flat-ttl:hover {
            cursor: pointer; }
        .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-flats-ttl .selected-ttl {
          background: #004A74;
          color: #FFFFFF; }
      .individual-project .content-box-floor-plan .flex-container-floor-plan .floor-img-container {
        display: flex;
        justify-content: center;
        align-items: center; }
        .individual-project .content-box-floor-plan .flex-container-floor-plan .floor-img-container .ind-floor-plan-img {
          margin-top: 20px;
          width: fit-content;
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 700px;
          height: 413.66px; }
        .individual-project .content-box-floor-plan .flex-container-floor-plan .floor-img-container .ind-floor-plan-img img {
          width: 100%;
          height: 100%; }
      .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-floor-plan-desc {
        height: fit-content; }
        .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-floor-plan-desc .para {
          padding: 2% 10%;
          margin: 0%; }
  @media only screen and (max-width: 1100px) {
    .individual-project .content-box-4 .mobile-testimonail {
      padding: 0% 0%;
      display: block; }
    .individual-project .content-box-4 .flex-container {
      display: none; } }
  @media only screen and (max-width: 900px) {
    .individual-project .dividers {
      display: none; }
    .individual-project .mobiledividers {
      width: 80%;
      padding: 0% 0% 5%;
      display: block; }
    .individual-project .content-box-1 .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .individual-project .content-box-1 .inner .img-container {
        width: 90vw;
        height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .individual-project .content-box-1 .inner .img-container .project-img {
          width: 100%;
          height: 100%; }
      .individual-project .content-box-1 .inner .elements-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 90vw;
        height: 15vh; }
        .individual-project .content-box-1 .inner .elements-container .boxes {
          font-size: 12px;
          text-align: center;
          padding: 2px; }
    .individual-project .content-box-2 {
      padding-top: 20%; }
      .individual-project .content-box-2 .btn-container .btn-div .indproject-btn {
        word-wrap: break-word;
        font-size: 15.5px;
        width: 165px; }
      .individual-project .content-box-2 .title {
        padding: 10% 0% 5%; }
      .individual-project .content-box-2 .description {
        padding: 0 15%;
        font-size: 16px; }
    .individual-project .content-box-3 {
      padding-top: 20%; }
      .individual-project .content-box-3 .flex-container {
        padding: 0%; }
    .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-flats-ttl {
      width: 90%; }
      .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-flats-ttl .flat-ttl {
        font-size: x-small;
        padding: 5px 10px 5px; }
    .individual-project .content-box-floor-plan .flex-container-floor-plan .floor-img-container .ind-floor-plan-img {
      width: 100%;
      margin: auto;
      margin-top: 10px; }
      .individual-project .content-box-floor-plan .flex-container-floor-plan .floor-img-container .ind-floor-plan-img .ind-floor-plan-img img {
        height: auto; }
    .individual-project .content-box-floor-plan .flex-container-floor-plan .ind-floor-plan-desc {
      padding-top: 2%;
      padding-bottom: 5%;
      font-size: small; } }

.admin {
  /*----------Projects------------*/ }
  .admin .addprojects {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .admin .addprojects-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .admin .detailmodal-box {
    position: fixed;
    top: 50vh;
    left: 50vw;
    max-width: 90vw;
    background-color: #7898aa;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-radius: 1rem;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px; }
  .admin .crosstag {
    backdrop-filter: blur(500rem); }
  .admin .modal-box__label {
    width: fit-content;
    padding: 1rem;
    word-wrap: normal; }
  .admin .top-left,
  .admin .top-right,
  .admin .bottom-left,
  .admin .bottom-right {
    width: 50%;
    padding: 0% 2%; }
  .admin .bottom {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 39vh; */
    height: 100%;
    padding-top: 10px;
    backdrop-filter: blur(100rem); }
  .admin .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .admin .top {
    padding-top: 10px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(100rem); }
  .admin .topinput {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-between; }
  .admin .bottominput {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    width: 100%; }
  .admin .crosstag {
    width: 100%;
    height: 100%; }
  .admin #line-detailModal {
    margin: auto;
    width: 40%;
    border-top: 2px solid #080808; }
  .admin .div-cross {
    position: absolute;
    right: 5px;
    padding: 5px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 1000; }
  .admin textarea {
    border-radius: 6px;
    width: 60%;
    resize: none; }
  .admin .button11 {
    border: none;
    color: white;
    /* padding: 15px 32px; */
    padding: 12px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 2px 1px;
    cursor: pointer;
    background-color: rgba(2, 38, 59, 0.35);
    border-radius: 8px;
    /* font-size: large; */ }
  .admin .button11:hover {
    background-color: #5c7882; }
  .admin .textareaProject {
    padding-top: 5px; }
  .admin input:focus,
  .admin textarea:focus,
  .admin select:focus {
    outline: 2px solid #367ae7; }
  .admin table {
    table-layout: fixed;
    width: 100%; }
  .admin .project-images {
    width: 280px;
    height: 320px; }
  .admin .flatimage {
    width: 100px;
    height: 120px; }
  .admin .addnew {
    position: fixed;
    top: 100px;
    left: 110px; }

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%; }

.mainNavbar {
  width: 100vw;
  height: fit-content;
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); }
  .mainNavbar .dropdown .nav-links {
    display: none; }
  .mainNavbar .inner {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-between; }
    .mainNavbar .inner .logo-container {
      width: 171.81px;
      height: 100.01px; }
      .mainNavbar .inner .logo-container .logo-img {
        display: block;
        width: 100%;
        height: 100%; }
      .mainNavbar .inner .logo-container .mobilelogo-img {
        display: none; }
    .mainNavbar .inner .links-container {
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      .mainNavbar .inner .links-container .nav-links {
        padding: 1% 3%;
        text-decoration: none;
        white-space: nowrap;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        letter-spacing: 0.03em;
        color: #4f4f4f;
        cursor: pointer; }
        .mainNavbar .inner .links-container .nav-links::after {
          content: "";
          background: -webkit-linear-gradient(left, #0f497e 0%, #53caf5 109.3%);
          display: block;
          height: 1.7px;
          width: 20px;
          border-radius: 5px;
          bottom: 0; }
        .mainNavbar .inner .links-container .nav-links:hover::after {
          content: "";
          background: -webkit-linear-gradient(left, #0f497e 0%, #53caf5 109.3%);
          display: block;
          height: 1.7px;
          width: 45px;
          border-radius: 5px;
          bottom: 0;
          transition: 0.4s;
          transition-timing-function: ease-in-out; }
    .mainNavbar .inner .enquire-btn {
      border: 0;
      background: #2596c7;
      padding: 10px 22px 8px;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #ffffff;
      box-shadow: 0px 4px 2px rgba(3, 0, 0, 0.1);
      cursor: pointer;
      position: absolute !important;
      right: 60px; }
    .mainNavbar .inner .enquire-btn:hover {
      background: #004a74; }
    .mainNavbar .inner .hamburger {
      visibility: hidden; }
  @media only screen and (max-width: 1000px) {
    .mainNavbar {
      position: fixed;
      bottom: 0%;
      justify-content: center; }
      .mainNavbar .dropdown .nav-links {
        display: block;
        text-decoration: none;
        padding: 3% 3%;
        text-decoration: none;
        white-space: nowrap;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.03em;
        color: #4f4f4f;
        cursor: pointer; }
      .mainNavbar .inner {
        width: 100%;
        padding: 0% 5% 0% 2%; }
        .mainNavbar .inner .logo-container {
          width: 72.8px;
          height: 52.41px; }
          .mainNavbar .inner .logo-container .logo-img {
            display: none; }
          .mainNavbar .inner .logo-container .mobilelogo-img {
            display: block;
            width: 100%;
            height: 100%; }
        .mainNavbar .inner .links-container {
          display: none; }
        .mainNavbar .inner .enquire-btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #2596c7;
          padding: 10px 22px 8px;
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.03em;
          color: #ffffff;
          margin-right: 45px;
          position: static !important; }
          .mainNavbar .inner .enquire-btn:hover {
            background: #0f497e; }
        .mainNavbar .inner .hamburger {
          cursor: pointer;
          visibility: visible; } }

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%; }

.footer {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #004a74;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff; }
  .footer .inner {
    width: 90%;
    height: fit-content;
    padding: 2% 0% 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .footer .inner .upper {
      width: 100%;
      padding-right: 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      justify-content: space-between; }
      .footer .inner .upper .links-outer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: flex-start; }
        .footer .inner .upper .links-outer .links-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .footer .inner .upper .links-outer .links-container .columns {
            height: 140px;
            padding: 0% 5%;
            width: fit-content;
            white-space: nowrap;
            text-align: left; }
            .footer .inner .upper .links-outer .links-container .columns__headings {
              padding-bottom: 5%;
              font-family: "Roboto Flex";
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 150%;
              text-decoration: none;
              color: white !important; }
            .footer .inner .upper .links-outer .links-container .columns__subheadings .text {
              font-family: "Roboto Flex";
              color: white;
              text-decoration: none;
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              line-height: 100%;
              padding: 4px 0px;
              margin: 0%; }
    .footer .inner .footer-divider {
      width: 90%;
      padding: 1% 0%; }
    .footer .inner .lower {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 150%; }
  @media only screen and (max-width: 1000px) {
    .footer {
      padding-bottom: 9%; } }
  @media only screen and (max-width: 768px) {
    .footer {
      padding-bottom: 16%; }
      .footer .inner .upper {
        display: none; } }

* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%; }

.testimonial {
  width: 100vw;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../images/allbg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .testimonial .headings {
    font-family: "Cinzel";
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #474747; }
  .testimonial .dividers {
    width: 80%;
    padding: 0% 0% 5%; }
  .testimonial .mobiledividers {
    display: none;
    width: 80%; }
  .testimonial .inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    padding-top: 120px; }
    .testimonial .inner .content-box {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .testimonial .inner .content-box .card {
        width: 60%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(15, 73, 126, 0.05);
        box-shadow: 0px 32px 34px rgba(0, 0, 0, 0.133714);
        border-radius: 25px;
        margin-bottom: 50px;
        padding: 3% 5%; }
        .testimonial .inner .content-box .card .card-divider {
          width: 70%;
          padding: 5px 0 20px; }
        .testimonial .inner .content-box .card .card-divider-medium {
          display: none;
          padding: 5px 0 20px; }
        .testimonial .inner .content-box .card .customer {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 1000;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.1em;
          color: #474747; }
        .testimonial .inner .content-box .card .occupation {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.03em;
          color: #474747; }
        .testimonial .inner .content-box .card .paragraph {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          text-align: justify;
          letter-spacing: 0.03em;
          color: #474747;
          position: relative; }
          .testimonial .inner .content-box .card .paragraph .quote-icon {
            position: absolute;
            left: -20px;
            top: -7px; }
        .testimonial .inner .content-box .card .caption {
          font-family: "Roboto Flex";
          font-style: medium;
          font-weight: 500;
          font-size: 24px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.1em;
          color: #474747;
          padding-top: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .testimonial .inner .content-box .card .caption .building-icon {
            font-size: 32px;
            padding-right: 10px;
            color: #004a74; }
  @media only screen and (max-width: 1000px) {
    .testimonial .inner {
      padding-top: 20px; } }
  @media only screen and (max-width: 768px) {
    .testimonial .inner .mobiledividers {
      display: block;
      padding: 1% 0% 5%; }
    .testimonial .inner .card-divider {
      display: none; }
    .testimonial .inner .dividers {
      display: none; }
    .testimonial .inner .content-box .card {
      width: 80%;
      padding: 7%; }
      .testimonial .inner .content-box .card .card-divider-medium {
        display: block;
        width: 80%; }
      .testimonial .inner .content-box .card .caption {
        padding: 50px 10% 0; }
        .testimonial .inner .content-box .card .caption .building-icon {
          padding: 0%; } }

.notfound {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .notfound .text {
    width: 500px;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 1000;
    font-size: 24px;
    line-height: 150%;
    padding-bottom: 8px;
    letter-spacing: 0.1em;
    color: #474747;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: flex-start; }
    .notfound .text .moveback {
      text-decoration: none;
      border: 0;
      background: #2596c7;
      padding: 5px 11px 5px;
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.03em;
      color: #ffffff;
      box-shadow: 0px 4px 2px rgba(3, 0, 0, 0.1);
      cursor: pointer;
      margin-bottom: 50px; }
      .notfound .text .moveback:hover {
        background: #0f497e; }
  .notfound .image404 {
    width: 500px;
    height: 500px; }
  @media only screen and (max-width: 768px) {
    .notfound .text {
      width: 300px; }
    .notfound .image404 {
      width: 300px;
      height: 300px; } }
